// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll{
    overflow-y: scroll;
    scrollbar-color: transparent blue;
    background-color: transparent;
    height: 180px;
    max-height: 180px;
    position: relative;
    bottom:40px;
    z-index: 1;
}

/* For Webkit browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
    width: 10px; /* Set the width of the scrollbar */
}
  
::-webkit-scrollbar-thumb {
    background-color: #00F19F; 
    border-radius: 20px;  
}

/* Default styles for screens wider than 14 inches */
.element {
    width: 615px;
}
  
  /* Media query for screens up to 14 inches */
@media (max-width: 16in) {
    .element {
        width: 370px; /* Set the width to 100% for screens up to 14 inches */
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/scroll.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iCAAiC;IACjC,6BAA6B;IAC7B,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,UAAU;AACd;;AAEA,+CAA+C;AAC/C;IACI,WAAW,EAAE,mCAAmC;AACpD;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA,oDAAoD;AACpD;IACI,YAAY;AAChB;;EAEE,4CAA4C;AAC9C;IACI;QACI,YAAY,EAAE,sDAAsD;IACxE;AACJ","sourcesContent":[".scroll{\n    overflow-y: scroll;\n    scrollbar-color: transparent blue;\n    background-color: transparent;\n    height: 180px;\n    max-height: 180px;\n    position: relative;\n    bottom:40px;\n    z-index: 1;\n}\n\n/* For Webkit browsers (e.g., Chrome, Safari) */\n::-webkit-scrollbar {\n    width: 10px; /* Set the width of the scrollbar */\n}\n  \n::-webkit-scrollbar-thumb {\n    background-color: #00F19F; \n    border-radius: 20px;  \n}\n\n/* Default styles for screens wider than 14 inches */\n.element {\n    width: 615px;\n}\n  \n  /* Media query for screens up to 14 inches */\n@media (max-width: 16in) {\n    .element {\n        width: 370px; /* Set the width to 100% for screens up to 14 inches */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
